/* Fonction pour initialiser les swiper "visual_scroller" & "jqzoom" */
function swiperJqzoomAndVisualScroller() {
    var productThumbs = new Swiper("#visual_scroller", { // Swiper Visual Scroller (mini images)
        slidesPerView: 3,
        spaceBetween: 38,
        direction: 'horizontal',
        loop: false,
        navigation: {
            prevEl: '.thumbPrev',
            nextEl: '.thumbNext',
        },

        breakpoints: {
            // when window width is >= 1281px
            1281: {
                spaceBetween: 43,
            },
        }
    });
    var main_productVisu = new Swiper("#jqzoom", { // Swiper Jqzoom (image principale)
        thumbs: {
            swiper: productThumbs
        },
        slidesPerView: 1,
        loop: false,
    });
}

/*** is checked bundle fiche produit  (Function use in load page and in load product) ***/
function checkAddBasket() {
    $('.blocAddBasket').each(function () {
        if ($(this).find($('[name="unit_lot"]:checked')).length) {
            $(this).addClass('isChecked');
        } else {
            $(this).removeClass('isChecked');
        }
    });
}

/** Rappel de la fonction au clic sur les inputs des lots.**/
$('.blocAddBasket').on("click", function () {
    checkAddBasket();
});

$(function () {
    $('#shad_abox').on("click", function () {
        closeMultiShad();
        $('.lightbox.actif').removeClass('actif');
    });

    swiperJqzoomAndVisualScroller();

    /*see more rayon*/
    $('.thumbslide .see_more').on('click', function (e) {
        $('.thumbslide').removeClass('active');
        $(this).parents('.thumbslide').addClass('active');
    });

    /*** is checked bundle fiche produit ***/

    if ($('.wrapperAddBasket').length) {
        checkAddBasket();
    }
});

function showsendfriendmodal(product_id, path_web, type) {
    var elname = type === "look_basket" ? "modbox" : "sendfriendbox";
    var actualposition = document.documentElement.scrollTop;

    if (type !== 'look_basket') {
        var psnok = document.getElementById('product_sendfriend_nok');
        var psfok = document.getElementById('product_sendfriend_ok');
        if (psnok) {
            psnok.innerHTML = '';
            psnok.style.display = 'none';
        }
        if (psfok) {
            psfok.innerHTML = '';
            psfok.style.display = 'none';
        }

        prodGetProductDisplay(product_id, path_web, type);

        openMultiShad("sendfriendbox");
        $("#shad_abox").addClass('actif');

    } else {
        show_lightbox(elname);
        $(".loader", "#bloc_add_basket").hide();
    }
}

function prodGetProductDisplay(id, path_web, type) {
    if (typeof type == "undefined") {
        var response = ajax_file(
            path_web + "ajax_get_infos_send_friend.php?product_id=" + id
        );
        document.getElementById("sendfriend_prodinfos").innerHTML = response;
    } else {
        if (type == "look") {
            $("#titre_popup").html(Translator.translate('sendfriend_title_look'));
            $("#friend_form").show();
            $("#btn_popup_panier").hide();

            $("#bloc_btn_look").show();
            $("#bloc_btn_loader_look").hide();
        } else if (type == "look_basket") {
            $("#titre_popup").html("Vous venez d'ajouter le look suivant");
            $("#friend_form").hide();
            $("#btn_popup_panier").show();

            $("#bloc_btn_look").show();
            $("#bloc_btn_loader_look").hide();
        } else {
            $("#titre_popup").html("Vous venez d'ajouter le produit suivant");
            $("#friend_form").hide();
            $("#btn_popup_panier").show();
        }
    }

    if (
        navigator.userAgent.indexOf("MSIE") != -1 &&
        navigator.userAgent.indexOf("6.") != -1
    ) {
        var svn = document.getElementsByTagName("SELECT");
        for (a = 0; a < svn.length; a++) {
            svn[a].style.visibility = "hidden";
        }
    }
}


/* Fonction pour ouvrirune lightbox de l'image zoom
    Paramamètres :
        slide => image ou on souhaite afficher le zoom
*/
function zoomImg(slide) {
    if ($("#zoom_box_wrapper .swiper-slide").length > 0) { // Initialisation du Swiper du Zoom
        var main_productVisu = new Swiper('#zoom_box_wrapper', {
            slidesPerView: 1,
            navigation: {
                prevEl: '.zoomPrev',
                nextEl: '.zoomNext',
            },
            preloadImages: false,
            lazy: true,
            loadPrevNext: true,
            loadOnTransitionStart: true
        });
        main_productVisu.slideTo(slide - 1);
    }

    $('#zoom_box_wrapper .img_large').on('click', function () { // Fermeture de la lightbox et cacher le shad au clique sur l'image du zoom
        closeMultiShad('zoomBox');
    });

    openMultiShad('zoomBox'); // Ouverture de la lightbox zoom
}

/* Fonction pour gérer l'affichage de l'input alert stock ou cacher les messages de confirmation ou d'erreurs  */
function lightboxAlertStock() {

    var $taille_id = $("#tailleProd").val(),    // Récupère la valeur de la taille sélectionnée
        $couleur_id = $("#couleurProd").val();  // Récupère la valeur de la couleur séléctionnée

    if ($couleur_id == '') { // Si pas de couleurs sélectionnées
        alert(translate('js_error_couleur'));
        return;
    }

    var isFormVisible = $('.bloc_add_alert_form').is(':visible');               // Boolean  => Savoir l'input alert stock est visible
    var isMessageVisible = $('.bloc_add_alert_confirmation').is(':visible');    // Boolean  => Savoir le bloc de confirmation d'alert stock est visible
    var isErrorVisible = $('.bloc_add_alert_erreur').is(':visible');            // Boolean  => Savoir le bloc d'erreur d'alert stock est visible

    if (isFormVisible || isMessageVisible || isErrorVisible) {  // Si l'un des trois blocs est visible, on fait :

    } else {
        $('.bloc_add_alert_form').slideDown('fast'); // Sinon on affiche l'input Alert Stock
    }

    //display slide alert stock fiche produit
    if ($('body.product_page').length) {
        $('#shad_rollovermenu').addClass('actif');
        $('.container_lightbox_alert_stock').addClass('actif');
        /* Scrollbar lightbox produit indisponible */
        $('.container_lightbox_alert_stock').overlayScrollbars({
            scrollbars: {
                autoHide: "leave",
                autoHideDelay: 0,
            }
        });
    }

    if (wShop.$refs.wAlertestockForm) {
        wShop.$refs.wAlertestockForm.reloadBloc += 1;
    }
}

function closeLightboxStock() {
    if ($('body.product_page').length) {
        $('#shad_rollovermenu').removeClass('actif');
        $('.container_lightbox_alert_stock').removeClass('actif');
    }
}

function resetThumbSwiperFP() {
    var swiper_FP_main_push_height = $("#jqzoom").height();
    var swiper_FP_main_push_width = $("#jqzoom").width();
    $("#visual_scroller_wrapper").addClass('is-active');

}
